const MIM = ()=> {
    return (
      <div>
        <h5>MIM - January 2024 hack</h5>
        <blockquote>
        Following <a href="https://twitter.com/EXVULSEC/status/1752357798783103158" rel="noopener noreferrer">the January 2024 hack</a> the debt for the yv-3Crypto and the magicAPE cauldrons are calculated as follow: outstanding cauldron borrow - TVL.
        <br/>
        <br/>
          <footer>
            <cite>Risk DAO</cite>
          </footer>
        </blockquote>
      </div>
    )
  }
  
  export default MIM
  